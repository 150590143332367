@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


// COLORS

$primary: #F0A500;
$primary-light: lighten($primary, 50%);
$secondary: #E45826;
$secondary-light: lighten($secondary, 10%);
$dark: #211F1F;
$light: #FFFFFF;

$body-bg: #F5F5F5;
$hover-bg: #211F1F;
$focus-ring-color: lighten($primary, 30%);
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $primary;
$form-select-focus-box-shadow: 0 0 5px $focus-ring-color, 0 0 10px rgba($focus-ring-color, 0.5);
$dropdown-bg: $light;
$dropdown-link-hover-bg: $dark;
$dropdown-link-hover-color: $body-bg;
$box-shadow: 0px 3px 3px 1px #00000040;
$caret-width: 0;
$caret-spacing: 0;
$pagination-active-bg: $primary;
$modal-backdrop-opacity: .25;

$theme-colors: (
  'primary': $primary,
  'primary-light': $primary-light,
  'secondary': $secondary,
  'secondary-light': $secondary-light,
  'dark': $dark,
  'light': $light,
  'text-dark': $dark,
  'text-light': #F5F5F5,
);

:focus {
  outline: 0px;
}

textarea:focus, select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
.uneditable-input:focus {   
  box-shadow: 0 0 5px $focus-ring-color, 0 0 10px rgba($focus-ring-color, 0.5) !important;
}

.nav-link:focus {
  outline: none;
  box-shadow: none;
}

.bg-gradient-primary {
  background: linear-gradient(180deg, #1A1616 0%, #2B2727 100%);
}

.bg-gradient-secondary {
  background: linear-gradient(to right, #E45826, #F0A500);
}

.btn-outline-secondary {
  &:hover {
    background-color: $secondary !important; // Example: Change to blue
    color: $light !important; // Example: Darker blue border on hover
  }
}

.page-link {
  border: 1px solid $dark !important;
  color: $dark !important;
}

.alert-primary {
  background-color: antiquewhite !important;
  border: 1px solid $primary !important;
  color: $secondary !important;
}


// FONTS

$font-family-base: 'Inter', sans-serif;

h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
  font-weight: bold !important;
}

@font-face {
  font-family: 'Ethnocentric';
  src: url('../assets/fonts/Ethnocentric.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.website-name {
  font-family: 'Ethnocentric', sans-serif;
  font-weight: normal;
}

.text-gradient {
  background: linear-gradient(to bottom, #E45826, #F0A500);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

// CUSTOM CLASSES

.feature-icon {
  width: 4rem;
  height: 4rem;
  border-radius: .75rem;
}

.btn-icon {
  padding: 0.5rem;
  border: none;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: transparent;
  color: $secondary;
  background-color: antiquewhite;
}

.btn-icon:hover {
  background-color: antiquewhite;
}

.sidebar {
  height: calc(100vh - 3rem);
  width: 225px;
}

.nav-item {
  position: relative;
}

.nav-item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: $secondary;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out 0s;
}

.nav-item:hover:before,
.nav-item.active:before {
  visibility: visible;
  transform: scaleX(1);
}

.sidebar-link {
  text-decoration: none;
  color: $body-bg;
  outline: none;
  box-shadow: none;
}

.sidebar-link.active {
  background-color: $secondary;
}

.nav-link.active {
  font-weight: bold;
}

.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.custom-container {
  // width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background-color: $light;
  box-shadow: 0px 3px 3px 1px #00000040;
  overflow: auto;
}

.card-group {
  border-radius: 1rem !important;
  overflow: hidden !important;
}

.card {
  border: none !important;
}

.shape-decor {
  position: absolute;
  top: 35%;
  left: 40%;
  width: 80%;
  height: 70%;
  background-color: $secondary;
  border-radius: 2rem;
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 0;
}

// .left-wrapper {
//   flex: 0 0 250px;
// }

.right-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  text-wrap: nowrap;
}

.right-wrapper.full-width {
  flex: 1;
}

@media (max-width: 576px) {
  .rounded-top-sm {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
}

@media (min-width: 768px) {
  .rounded-start-md {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
}

@media (max-width: 576px) {
  .rounded-bottom-sm {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
}

@media (min-width: 768px) {
  .rounded-end-md {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
}

// CALENDAR

.rbc-time-content .rbc-time-slot {
  font-size: 0.75rem;
}

.rbc-header {
  font-size: 0.8rem;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-show-more {
  color: lighten($dark, 25%);
}

.rbc-event-label {
  display: none;
}

.rbc-button-link {
  font-size: 0.75rem;
}

.rbc-time-header .rbc-overflowing {
  margin-right: 0;
}

.rbc-agenda-table thead {
  background-color: $primary-light;
}

.rbc-agenda-table td {
  border: none !important;
  cursor: pointer;
}

.rbc-agenda-table tr {
  border-top: 3px solid #F8D27F;
  cursor: pointer;
}


.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.shiny-image {
  width: 100%;
  height: auto;
  display: block;
}

.shine-overlay {
  position: absolute;
  top: 0;
  left: -150%;
  width: 150%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-20deg);
  animation: shine 2s infinite;
}

@keyframes shine {
  from {
    left: -150%;
  }
  to {
    left: 150%;
  }
}

@import '~bootstrap/scss/bootstrap';
